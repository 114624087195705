import React, { useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const WriteTreatment = ({
  toggleMessage,
  showMessage,
  leadId,
  userId,
  patientId,
  getLeadbyId,
  id,
}) => {
  const [loading, setLoading] = useState(false);
  const [treatmentInput, setTreatmentInput] = useState("");

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!treatmentInput) newErrors.healthIssue = "Please Write a Treatment";
    return newErrors;
  };

  const handleSelectChange = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      const errorMessages = Object.values(validationErrors).join("\n");
      alert(errorMessages);
      return;
    }
    setLoading(true);

    try {
      const generateUniqueId = () => {
        const timestamp = Date.now();
        const randomNum = Math.floor(Math.random() * 1000);
        return `${timestamp}${randomNum}`;
      };

      const uniqueSpecialityId = generateUniqueId();
      const response = await axiosInstance.post("/assignSpecialityPatient", {
        lead_id: leadId,
        user_id: userId,
        patient_id: patientId,
        speciality_id: uniqueSpecialityId,
        speciality_name: treatmentInput,
      });

      alert("Successful");
      toggleMessage();
      setTreatmentInput("");
      getLeadbyId(id);
    } catch (error) {
      console.error("There was an error making the request:", error);
      alert("An error occurred while making the request");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div
        className="popup"
        data-popup="popup-1"
        style={{ display: showMessage ? "block" : "none" }}
      >
        <div className="popup-inner2">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="popup-close"
                data-popup-close="popup-1"
                data-dismiss="modal"
                onClick={toggleMessage}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <h2>Write Treatment Name here </h2>

            {/* <div className="treatment-form">
              <div className="inputbox">
                <select
                //   value={healthIssue}
                //   onChange={(e) => setHealthIssue(e.target.value)}
                >
                  <option value="">Health Issue?</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            </div> */}

            <div className="treatment-form" style={{ paddingTop: "10px" }}>
              <div className="inputbox">
                <input
                  className="treatmentbox"
                  type="text"
                  name="treatment"
                  value={treatmentInput}
                  onChange={(e) => setTreatmentInput(e.target.value)}
                  autocomplete="off"
                ></input>
              </div>
            </div>
            <button
              type="submit"
              name="en"
              className="home-button"
              onClick={handleSelectChange}
            >
              {loading ? "Loading..." : "Submit Now"}
              <img
                src="https://images.medflick.com/images/2023/01/arrow-c.png"
                alt="arrow-Icon"
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WriteTreatment;
