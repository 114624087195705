// UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ id: null, role: null });
  const [localLoading, setLocalLoading] = useState(true);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const userRole = localStorage.getItem("userRole");
    if (userId && userRole) {
      setUser({ id: userId, role: userRole });
    }
    setLocalLoading(false);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, localLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
