import React, { useState } from "react";
import { FaComment } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

const ChatBox = () => {
  const [expand, setExpand] = useState(false);

  const openChat = () => {
    setExpand(true);
  };

  const closeChat = (event) => {
    event.stopPropagation();
    setExpand(false);
  };

  console.log("expand", expand);

  return (
    <>
      <div
        className={`floating-chat enter ${expand ? "expand" : ""}`}
        onClick={openChat}
      >
        <div className="chat-box-icon">
          <i className="fa fa-comments" aria-hidden="true">
            <FaComment fontSize={22} />
          </i>
        </div>
        <div className={`chat-section chat-box ${expand ? "enter" : ""}`}>
          <div className="header">
            <h2>Chat</h2>
            <button onClick={closeChat}>
              <i className="fa fa-times" aria-hidden="true">
                <IoIosClose />
              </i>
            </button>
          </div>
          <div className="scrollbar-chat">
            <div className="chat-box-doc" style={{ background: "#fff" }}>
              <div className="chat">
                <img src="/images/2024/01/c-img.png" alt="" />
                <p className="msg">
                  Hi there! <br />
                  Looking to get started? I can help answer to your questions!
                </p>
              </div>

              <div className="chat">
                <img src="/images/2024/01/v-icon.png" alt="" />
                <p className="msg">
                  Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
                  eiusmod
                </p>
              </div>
              <div className="chat">
                <img src="images/2024/01/c-img.png" alt="" />
                <p className="msg">
                  Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
                  eiusmod
                </p>
              </div>

              <div className="chat">
                <img src="images/2024/01/v-icon.png" alt="" />
                <p className="msg">
                  Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
                  eiusmod
                </p>
              </div>

              <div className="chat">
                <img src="images/2024/01/c-img.png" alt="" />
                <p className="msg">
                  Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
                  eiusmod
                </p>
              </div>

              <div className="chat">
                <img src="images/2024/01/b-icon.png" alt="" />
                <p className="msg">
                  Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
                  eiusmod
                </p>
              </div>

              <div className="chat">
                <img src="images/2024/01/c-img.png" alt="" />
                <div className="bubble msg">
                  <div className="ellipsis one"></div>
                  <div className="ellipsis two"></div>
                  <div className="ellipsis three"></div>
                </div>
              </div>
            </div>

            <div className="chat-bottom-doc text-box">
              <input type="text" placeholder="Write your message here..." />

              <a href="#" className="file-paperclip">
                <i className="fa fa-paperclip" aria-hidden="true"></i>
              </a>
              <button type="submit" name="en" className="send-now">
                Send Now{" "}
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
