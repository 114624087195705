import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import "./profile.css";
import LeftSlider from "../../Inc/LeftSlider";
import Header from "../../Inc/header/Header";

const UpdateProfile = () => {
  const [user, setUser] = useState({
    name: "",
    password: "",
  });
  // const [phone,setPhone] = useState("")
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const phone = localStorage.getItem("userPhone");
    const email = localStorage.getItem("userEmail");
    const userid = localStorage.getItem("userId");
    if (userid) {
      setUserId(userid);
    }
    if (phone || email) {
      const fetchUserDetails = async () => {
        setLoading(true);
        const requestBody = {};
        if (phone && phone !== "null") requestBody.phone = phone;
        if (email && email !== "null") requestBody.email = email;
        try {
          const response = await axiosInstance.post("/profile", requestBody);
          const userData = response.data.data;
          setUser({
            name: userData.name,
          });
          setLoading(false);
        } catch (err) {
          setError("Error fetching user details");
          setLoading(false);
        }
      };
      fetchUserDetails();
    }
  }, []);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosInstance.post("/updateProfile", {
        name: user.name,
        password: user.password,
        user_id: userId,
      });

      // Display the message from the response in the alert
      alert(response.data?.message || "Profile updated successfully");
      setLoading(false);
    } catch (err) {
      setError("Error updating profile");
      setLoading(false);
    }
  };

  return (
    <>
      <section id="medflick-mod">
        <Header />
        <section id="medflick-mod">
          <div className="medflick-mod">
            <LeftSlider path="update-profile" />
            <div className="medflick-mod-right">
              <div className="scrollbar-1">
                <div className="query-form-page">
                  <h1>Update Profile</h1>
                  <div className="profile-update-container">
                    {loading && <p>Loading...</p>}
                    {error && <p className="error">{error}</p>}
                    <form
                      className="profile-update-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={user.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="password">Password:</label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          value={user.password}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <button type="submit" disabled={loading}>
                        Update Profile
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default UpdateProfile;
