import React, { useState } from "react";
import { useGetLeadApi } from "../../context/getAllLeadContext";

const Filter = () => {
  const [showFilter, setShowFilter] = useState(false);

  const { setPlatformSelect, platformSelect } = useGetLeadApi();

  const handlePlatFormSelect = (e) => {
    setPlatformSelect(e.target.value);
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };



  return (
    <>
      <div className="filters-box toggle" onClick={toggleFilter}>
        Filters <img src="images/2024/01/filters.png" />
      </div>

      <div id="target" style={{ display: showFilter ? "block" : "none" }}>
        <div className="ding">
          <select
            id="wiki"
            value={platformSelect}
            onChange={handlePlatFormSelect}
          >
            <option value="facebook">Facebook</option>
            <option value="whatsapp">WhatsApp</option>
            <option value="website">Website</option>
          </select>
        </div>
        <div className="ding">
          <select id="wiki">
            <option value="none" selected>
              {" "}
              Select Speciality{" "}
            </option>
            <option value="All"> Speciality name</option>
          </select>
        </div>
        <div className="ding">
          <select id="wiki">
            <option value="none" selected>
              {" "}
              Select Status{" "}
            </option>
            <option value="All"> Not Uploaded</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Filter;
