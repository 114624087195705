import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const ProtectedRoute = ({ children, allowedRoles, requireLogin }) => {
  const { user, localLoading } = useUser();

  if (localLoading === false) {
    if (requireLogin && !user.id) { 
      return <Navigate to="/login" replace />;
    }
    if (allowedRoles && !allowedRoles.includes(user.role)) {
      return <Navigate to="/admin-lead-list" replace />;
    }
  }

  return children;
};

export default ProtectedRoute;
