import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { handleLogout } from "../utils/handleLogout";

const LeftSlider = ({ path, role }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      handleLogout();
    }, 43200000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="medflick-mod-left">
        <div className="medflick-nav-left">
          <ul>
            <li className={path === "home" ? "active" : ""}>
              {role === "agent" ? (
                <Link to="/admin-lead-list">
                  <img src="/images/2024/01/01/1.png" alt="Admin Lead List" />{" "}
                  Admin Lead List
                </Link>
              ) : (
                <Link to="/">
                  <img src="/images/2024/01/01/1.png" alt="Home" /> Home
                </Link>
              )}
            </li>

            <li className={path === "update-profile" ? "active" : ""}>
              <Link to="/update-profile">
                <img src="/images/2024/01/01/2.png" /> Profile Update
              </Link>
            </li>

            {/* <li className={path === "call-log" ? "active" : ""}>
              <Link to="/call-log">
                <img src="/images/2024/01/01/2.png" /> Call Log
              </Link>
            </li> */}

            {/* {role !== "agent" && (
              <li>
                <Link to="/manish">
                  <img src="/images/2024/01/01/2.png" /> Leads
                </Link>
              </li>
            )} */}
            {/* {role !== "agent" && (
              <li>
                <a href="#">
                  <img src="/images/2024/01/01/3.png" /> VIL
                </a>
              </li>
            )} */}
            {role !== "agent" && (
              <li>
                <Link to="/create-user">
                  <img src="/images/2024/01/01/3.png" alt="Create User" />{" "}
                  Create User
                </Link>
              </li>
            )}
            {role !== "agent" && (
              <li className={path === "user-list" ? "active" : ""}>
                <Link to="/user-list">
                  <img src="/images/2024/01/01/3.png" /> User List
                </Link>
              </li>
            )}

            <li className={path === "add-patient" ? "active" : ""}>
              <Link to="/add-patient">
                <img src="/images/2024/01/01/3.png" /> Add Lead
              </Link>
            </li>
          </ul>
        </div>

        <div className="medflick-nav-bottom">
          <div className="medflick-box-bottom">
            <p>Help & Support</p>
            <ul>
              <li>
                <a href="#">
                  <img src="/images/2024/01/01/4.png" /> Contact Us
                </a>
              </li>
              <li onClick={handleLogout}>
                <a href="#">
                  <img src="/images/2024/01/01/4.png" /> Logout
                </a>
              </li>
              {/* <li>
                <a href="#">
                  <img src="/images/2024/01/01/5.png" /> reports@medflick.com
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSlider;
