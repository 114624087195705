import React, { useState, useEffect } from "react";
import { useGetLeadApi } from "../../context/getAllLeadContext";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const Graph = ({ adminLead }) => {
  const { totalLead } = useGetLeadApi();

  const [totalConverted, setTotalConverted] = useState({
    converted: 0,
    open: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch total_converted
        const responseConverted = await axiosInstance.get("/total_converted");
        const totalConvertedData = responseConverted.data?.toal_converted || 0; // Ensure a fallback value

        // Fetch LeadStatus
        const responseLeadStatus = await axiosInstance.get("/LeadStatus");
        const openLeads = responseLeadStatus.data?.LeadStatus || 0; // Ensure a fallback value

        // Update the state in one go
        setTotalConverted({
          converted: totalConvertedData,
          open: openLeads,
        });
      } catch (err) {
        console.log("error", err);
      }
    };

    fetchData(); // Call the function inside useEffect
  }, []);
  return (
    <>
      <div className="medflick-home-box">
        <ul>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Leads</h4>
                <h2>{totalLead ? totalLead : adminLead}</h2>
              </div>
              <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div>
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Converted</h4>
                <h2>{totalConverted?.converted}</h2>
              </div>
              <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div>
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
          </li>
          <li>
            <div className="total-leads-box">
              <div className="leads-text">
                <h4>Total Open</h4>
                <h2>{totalConverted?.open}</h2>
              </div>
              <div className="select">
                <select name="format" id="format">
                  <option disabled="">This Week</option>
                  <option selected="" value="This Week">
                    This Week
                  </option>
                </select>
              </div>
            </div>
            <div className="corp-reports-day">
              <img src="/images/2024/01/bg-1.png" />
              <div className="leads-day-box">
                <span>Sun</span>
                <span>Mon</span>
                <span>Tue</span>
                <span>Thu</span>
                <span>Fri</span>
                <span>Sat</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Graph;
