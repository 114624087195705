import React from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const CloseLead = ({
  leadId,
  status,
  getLeadbyId,
  getBackgroundColor,
  leadType,
}) => {
  const handleSubmit = async (leadType) => {
    try {
      const response = await axiosInstance.post(`/lead-update/${leadId}`, {
        status: leadType,
      });
      alert("Request was successful");
      getLeadbyId(leadId);
    } catch (error) {
      console.error("There was an error making the request:", error);
      alert("An error occurred while making the request");
    }
  };

  const handleSendMessageClick = (e) => {
    e.preventDefault();
    const userConfirmed = window.confirm("Do you want to Close the Lead?");
    if (userConfirmed) {
      handleSubmit("Close Lead");
    }
  };

  const handleSendMessageClickOpen = (e) => {
    e.preventDefault();
    const userConfirmed = window.confirm("Do you want to Open the Lead?");
    if (userConfirmed) {
      handleSubmit("");
    }
  };
  return (
    <>
      {status === "Close Lead" ? (
        <a
          href="#"
          onClick={handleSendMessageClickOpen}
          style={{
            background: getBackgroundColor(leadType),
          }}
        >
          Open Lead
        </a>
      ) : (
        <a
          href="#"
          onClick={handleSendMessageClick}
          style={{
            background: getBackgroundColor(leadType),
          }}
        >
          Close Lead
        </a>
      )}
    </>
  );
};

export default CloseLead;
