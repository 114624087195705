
import CryptoJS from 'crypto-js';

const secretKey = 'asdvghfsa75sdafdvfysva5rasgvwqevhgsvytf'; 

export const encryptId = (id) => {
  const encrypted = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
  return encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};


export const decryptId = (encryptedId) => {
  const correctedId = encryptedId.replace(/-/g, '+').replace(/_/g, '/');
  const bytes = CryptoJS.AES.decrypt(correctedId, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
