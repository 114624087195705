import LeftSlider from "../../Inc/LeftSlider";
import React, { useEffect, useState } from "react";
import "./x.css";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useUser } from "../../context/UserContext";

const CreateUser = () => {
  const { user } = useUser();
  const [role, setRole] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = {
      ...formData,
      added_by: user?.id,
      [name]: value,
    };

    if (name === "email") {
      const username = value.split("@")[0];
      updatedFormData = {
        ...updatedFormData,
        userName: username,
      };
    }

    setFormData(updatedFormData);
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Full Name is required";
    if (!formData.email) {
      newErrors.email = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email Address is invalid";
    }
    if (!formData.phone) newErrors.phone = "Phone Number is required";
    if (!formData.role) newErrors.role = "User Role is required";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setLoading(true);
    try {
      const response = await axiosInstance.post("/create_post", formData);
      alert("User Create Successfully");
      setFormData({
        name: "",
        email: "",
        phone: "",
        role: "",
      });
      setErrors({});
    } catch (err) {
      console.log({ apiError: err.message });
      alert("Getting Error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/roleList");
        setRole(response.data);
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section id="login-section">
        <div className="login-section">
          <div className="login-section-left">
            <div className="login-logo">
              <img src="/images/wavikar-logo.png" />{" "}
            </div>

            <div className="login-form">
              <h2>Create User</h2>
              <h3>Enter your details below to create your user</h3>
              <form onSubmit={handleSubmit}>
                <div className="login-form-box">
                  <div className="login-contact-form-box">
                    <label>Full Name</label>
                    <input
                      type="text"
                      placeholder="Enter your full name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                  </div>
                </div>

                <div className="login-form-box">
                  <div className="login-contact-form-box">
                    <label>Email Address</label>
                    <input
                      type="text"
                      placeholder="Enter your email address"
                      value={formData.email}
                      name="email"
                      onChange={handleChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                </div>

                <div className="login-form-box">
                  <div className="login-contact-form-box">
                    <label>Enter your Phone number</label>
                    <input
                      type="text"
                      placeholder="Enter your phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && <p className="error">{errors.phone}</p>}
                  </div>
                </div>

                <div className="login-form-box">
                  <div className="login-contact-form-box">
                    <label>User Role</label>
                    <select
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                    >
                      <option>Select Role</option>
                      {role?.map((e) => (
                        <option key={e?.id} value={e?.id}>
                          {e?.name}
                        </option>
                      ))}
                    </select>
                    {errors.role && <p className="error">{errors.role}</p>}
                  </div>
                </div>

                <div className="login-form-box">
                  <button
                    type="submit"
                    name="en"
                    className="submit-now"
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : "Sign Up"}
                  </button>
                </div>
              </form>

              {/* <div className="log-button-box">
                <p>Already have an account?</p>
                <a href="login.html" className="log-in">
                  login
                </a>
              </div> */}
            </div>
          </div>
          <div className="login-section-right">
            {/* <div className="login-section-rightbox">
              <h2>
                A legacy of <br />
                Truth, Trust and Care
              </h2>
              <img src="/images/wavikar-logo.png" />
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateUser;
