import React from "react";
import Header from "../../Inc/header/Header";
import AdminLeadList from "./AdminLeadList";

const AdminHome = () => {
  return (
    <>
      <section id="medflick-mod">
        <Header />
        <AdminLeadList />
      </section>
    </>
  );
};

export default AdminHome;
