import React, { useState, useEffect } from "react";
import Select from "react-select";
import { axiosInstance } from "../../axiosInstance/axiosInstance";

const ConvertedForSelect = ({ leadId, stages, getLeadbyId }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const options = [
    { value: "1", label: "OPD" },
    { value: "2", label: "IPD" },
    { value: "3", label: "Follow UP" },
    { value: "4", label: "Dead" },
  ];

  useEffect(() => {
    const initialStage = options.find((option) => option.label === stages);
    setSelectedCategory(initialStage || null);
  }, [stages]);

  const handleSelectChange = async (selectedOption) => {
    setSelectedCategory(selectedOption);
    const { value, label } = selectedOption;
    console.log(selectedOption);
    try {
      const response = await axiosInstance.post(`/lead-update/${leadId}`, {
        stages: label,
      });

      alert("Request was successful");
      getLeadbyId(leadId);
    } catch (error) {
      console.error("There was an error making the request:", error);
      alert("An error occurred while making the request");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Select
        options={options}
        placeholder="Select"
        onChange={handleSelectChange}
        value={selectedCategory}
      />
    </>
  );
};

export default ConvertedForSelect;
