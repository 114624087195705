import React from "react";

const Manish = () => {
  return (
    <div>
      <h1>You Are Authorize Person To this route </h1>
    </div>
  );
};

export default Manish;
