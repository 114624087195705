import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const ReportTemplateSend = ({
  leadPhoneNumber,
  leadName,
  getBackgroundColor,
  leadType,
}) => {
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://public.doubletick.io/whatsapp/message/template",
        {
          messages: [
            {
              content: {
                language: "en",
                templateName: "crm_welcome_template",
                // templateData: {
                //   body: {
                //     placeholders: [leadName, leadPhoneNumber, "Med@321"],
                //   },
                // },
              },
              from: "+917710055123",
              to: "+919911714157",
              messageId: uuidv4(),
            },
          ],
        },
        {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            Authorization: "key_Qg9Vcf5fNY",
          },
        }
      );

      if (response.data?.messages[0]?.status === "SENT") {
        alert("Report Template Send Succesfully");
      } else {
        alert("Error to send Template");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessageClick = (e) => {
    e.preventDefault();
    const userConfirmed = window.confirm("Do you want to send the template?");
    if (userConfirmed) {
      sendMessage();
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <a
        href="#"
        onClick={handleSendMessageClick}
        style={{
          background: getBackgroundColor(leadType),
        }}
      >
        Send Welcome Template
      </a>
    </>
  );
};

export default ReportTemplateSend;
