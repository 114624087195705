import React, { useState, useEffect } from "react";
import { axiosInstance } from "../axiosInstance/axiosInstance";

const LeadType = ({ leadId, leadtype, getLeadbyId }) => {
  const [selectedLeadType, setSelectedLeadType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (leadtype) {
      setSelectedLeadType(leadtype);
    } else {
      setSelectedLeadType("");
    }
  }, [leadtype]);

  const handleLeadTypeChange = async (e) => {
    const newLeadType = e.target.value;
    setSelectedLeadType(newLeadType);
    setLoading(true);
    try {
      const response = await axiosInstance.post(`/lead-update/${leadId}`, {
        lead_type: newLeadType,
      });
      if (response.status === 200) {
        console.log("Lead type updated successfully");
      }
      getLeadbyId(leadId);
    } catch (error) {
      console.error("Error updating lead type", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
        </div>
      )}
      <select value={selectedLeadType} onChange={handleLeadTypeChange}>
        <option value="">Lead Type</option>
        <option value="Hot">Hot</option>
        <option value="Warm">Warm</option>
        <option value="Cold">Cold</option>
        <option value="Dead">Dead</option>
      </select>
    </>
  );
};

export default LeadType;
