import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useUser } from "../../context/UserContext";
import { useWhatsappApi } from "../../context/whatsappContex";
import LeftSlider from "../../Inc/LeftSlider";
import LeadList from "./LeadList";
import Graph from "../../HomePage/components/Graph";
import { LuRefreshCcw } from "react-icons/lu";

const AdminLeadList = () => {
  const [adminLeadList, setAdminLeadList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const { teamData, fetchTeamData, postUserIdLeadId } = useWhatsappApi();
  const userId = user?.id;

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/getleadsbyagentid/${userId}`);
      setAdminLeadList(response.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
      fetchTeamData();
    }
  }, [userId]);

  return (
    <>
      <div className="medflick-mod">
        <LeftSlider role="agent" path="home" />

        <div className="medflick-mod-right">
          <div className="scrollbar-1">
            <Graph adminLead={adminLeadList?.length} />
            <div className="leads-search-doc">
              <h2>Leads</h2>

              <div className="leads-crm-search">
                <div className="leads-crm-search-box">
                  <input
                    type="text"
                    placeholder="Search ..."
                    name="name"
                    required=""
                  />
                </div>

                <button
                  className="refresh-button"
                  onClick={fetchData}
                  disabled={loading}
                >
                  <LuRefreshCcw className="refresh-icon" />
                  {loading ? "Refreshing..." : "Refresh"}
                </button>
              </div>
            </div>

            <div className="leads-patient-box">
              <div className="leads-patient-head">
                <div className="leads-patient-head-box1">Patient Name</div>
                <div className="leads-patient-head-box2">Country</div>
                <div className="leads-patient-head-box3">Source</div>
                <div className="leads-patient-head-box4">Lead Type</div>
                <div className="leads-patient-head-box5">Report Status</div>
                <div className="leads-patient-head-box6">Assign To</div>
                <div className="leads-patient-head-box7">Status</div>
                <div className="leads-patient-head-box8">Action</div>
              </div>

              {/* <LeadList /> */}
              <LeadList
                adminLeadList={adminLeadList}
                userId={userId}
                teamData={teamData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLeadList;
