import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { useUser } from "../../context/UserContext";

const Login = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email format");
      return false;
    }

    if (!password) {
      setError("Enter Your Password");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const response = await axiosInstance.post("/login", {
        email: email,
        password: password,
      });
      setLoading(false);
      if (response.data.data.id) {
        const userId = response.data.data?.id;
        const userRole = response.data.data?.role;
        setUser({ id: userId, role: userRole });
        localStorage.setItem("userId", response.data.data?.id);
        localStorage.setItem("userRole", response.data.data?.role);
        localStorage.setItem("userPhone", response.data.data?.phone);
        localStorage.setItem("userEmail", response.data.data?.email);
        localStorage.setItem("userName", response.data.data?.name);
        if (
          response.data.data?.role === "3" ||
          response.data.data?.role === "4"
        ) {
          navigate("/admin-lead-list");
        } else {
          navigate("/");
        }
      } else if (!response.data.data.id) {
        alert("Invaild email Id & Password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Invaild email Id & Password");
      setLoading(false);
    }
  };
  return (
    <>
      <section id="login-section">
        <div className="login-mid login-mk">
          <div className="login-box">
            <div className="login-box-left login-img-d">
              <img
                src="https://wgrowth.partners/wwpl/medflick_CRM/images/2024/01/sign-bg.png"
                alt="login-img"
              />
              {/* <div className="home-drbox">
                <h4>We value your Privacy</h4>
                <ul>
                  <li>
                    <img
                      src="https://images.medflick.com/images/2023/compliance helpline.png"
                      alt="icon1"
                    />
                    <h5>Compliance Helpline</h5>
                  </li>
                  <li>
                    <img
                      src="https://images.medflick.com/images/2023/confidentiality.png"
                      alt="icon2"
                    />
                    <h5>Confidentiality</h5>
                  </li>
                  <li>
                    <img
                      src="https://images.medflick.com/images/2023/transparent policie.png"
                      alt="icon3"
                    />
                    <h5>Transparent Policies</h5>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="login-box-right" style={{ marginTop: "5rem" }}>
              <h1>
                Welcome to{" "}
                <span style={{ color: "#ff6800" }}>Wavikar Eye Institute</span>
              </h1>
              <form onSubmit={handleSubmit}>
                <div className="inputbox">
                  <label>Email Address</label>
                  <input
                    autoComplete="off"
                    type="email"
                    name="name"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="inputbox">
                  <label>Password</label>
                  <div className="password-input-container">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={handlePasswordChange}
                      name="password"
                    />
                    <img
                      className="password-toggle-icon"
                      src={
                        showPassword
                          ? `https://images.medflick.com/images/ci_hide.svg`
                          : `https://images.medflick.com/images/ci_show.svg`
                      }
                      alt={showPassword ? "Hide Password" : "Show Password"}
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
                {error && (
                  <div className="error" style={{ color: "red" }}>
                    {error}
                  </div>
                )}
                <button type="submit" name="en" className="login-button">
                  {loading ? "Loading..." : "Login"}
                </button>
              </form>
              {/* <div className="account-box">
                <span style={{ color: "#ff6800", cursor: "pointer" }}>
                  Forgot Password
                </span>
              </div>
              <div className="account-box">
                Don't have an account? <a href="/sign-up">Sign Up</a>
              </div> */}
            </div>
            <div className="login-box-left login-img">
              <img
                src="https://images.medflick.com/images/2023/07/1.jpg"
                alt="login-icon"
              />
              <div className="home-drbox">
                <h4>We value your Privacy</h4>
                <ul>
                  <li>
                    <img
                      src="https://images.medflick.com/images/2023/compliance helpline.png"
                      alt="icon1"
                    />
                    <h5>Confidentiality</h5>
                  </li>
                  <li>
                    <img
                      src="https://images.medflick.com/images/2023/confidentiality.png"
                      alt="icon2"
                    />
                    <h5>Transparent Policies</h5>
                  </li>
                  <li>
                    <img
                      src="https://images.medflick.com/images/2023/transparent policie.png"
                      alt="icon3"
                    />
                    <h5>Compliance Helpline</h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
