import React, { createContext, useState, useContext } from "react";
import {
  axiosMedflickInstance,
  axiosInstance,
} from "../axiosInstance/axiosInstance";

const GetMedflickDataContext = createContext();

export const MedflickProvider = ({ children }) => {
  const [hospitals, setHospitals] = useState([]);
  const [treatmentUploadList, setTreatmentUploadList] = useState([]);

  const getAllUploadedTreatment = async () => {
    try {
      const response = await axiosInstance.get("/SpecialityList");
      setTreatmentUploadList(response.data.specialityList);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getAllHospitals = async () => {
    try {
      const response = await axiosMedflickInstance.get("/search");
      setHospitals(response.data.searchData.hospitals);
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <GetMedflickDataContext.Provider
      value={{
        getAllHospitals,
        hospitals,
        getAllUploadedTreatment,
        treatmentUploadList,
      }}
    >
      {children}
    </GetMedflickDataContext.Provider>
  );
};

export const useMedflickApi = () => {
  return useContext(GetMedflickDataContext);
};
