import React, { createContext, useState, useEffect, useContext } from "react";
import {
  axiosWhatsappInstance,
  axiosInstance,
} from "../axiosInstance/axiosInstance";
import useRoleCheck from "../utils/useRoleCheck";

const WhatsappContext = createContext();

export const WhatsappProvider = ({ children }) => {
  const [teamData, setTeamData] = useState([]);
  const [whatsAppLeadList, setWhatsAppLeadList] = useState([]);
  const allowedRoles = ["1"];
  const isAuthorized = useRoleCheck(allowedRoles);

  const fetchTeamData = async () => {
    try {
      const response = await axiosInstance.get("/getagent");
      setTeamData(response.data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const getWhatsAppLead = async () => {
    try {
      const response = await axiosInstance.get("/allmessage");
      setWhatsAppLeadList(response.data.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  const postUserIdLeadId = async (userId, leadId) => {
    // if (!isAuthorized) {
    //   alert("You do not have permission to perform this action");
    //   return;
    // }
    try {
      const response = await axiosInstance.post(
        `/useridleadid/${userId}/${leadId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return (
    <WhatsappContext.Provider
      value={{
        teamData,
        fetchTeamData,
        postUserIdLeadId,
        getWhatsAppLead,
        whatsAppLeadList,
      }}
    >
      {children}
    </WhatsappContext.Provider>
  );
};

export const useWhatsappApi = () => {
  return useContext(WhatsappContext);
};
